const paths = {
  Ready: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/readycasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/readycasino/Home"),
        },
      ],
      meta: {
        title: "AQUA",
        icon: "aqua.ico",
      },
    },

    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  Apple: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/applecasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/applecasino/Home"),
        },
      ],
      meta: {
        title: "RUSH CASINO",
        icon: "apple.ico",
      },
    },
  ],
  AGC: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/applecasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/applecasino/Home"),
        },
      ],
      meta: {
        title: "AGC ONLINE",
        icon: "apple.ico",
      },
    },

    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],

  Alps: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/alpscasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/alpscasino/Home"),
        },
      ],
      meta: {
        title: "Alps Casino",
        icon: "alps.ico",
      },
    },
    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  Royal: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/royalcasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/royalcasino/Home"),
        },
      ],
      meta: {
        title: "Royal Casino",
        icon: "royal.ico",
      },
    },
    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  Hh: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/hh/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/hh/Home"),
        },
      ],
      meta: {
        title: "H 에이치",
        icon: "hh.ico",
      },
    },
    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  inBaccarat: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/inbaccarat/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/inbaccarat/Home"),
        },
      ],
      meta: {
        title: "인바카라",
        icon: "baccarat.ico",
      },
    },
    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  RoyalTy: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/royalty/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/royalty/Home"),
        },
      ],
      meta: {
        title: "KVN88",
        icon: "royalty.ico",
      },
    },
  ],
  Gameds: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/gameds/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/gameds/Home"),
        },
      ],
      meta: {
        title: "Gameds",
        icon: "gameds.ico",
      },
    },
  ],
  Kingado: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/kingado/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/kingado/Home"),
        },
      ],
      meta: {
        title: "Kingado",
        icon: "kingado.ico",
      },
    },
  ],
  Road: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/roadcasino/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/roadcasino/Home"),
        },
      ],
      meta: {
        title: "로드(Road)",
        icon: "roadcasino.ico",
      },
    },
  ],
  Rush: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/slotplay/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/slotplay/Home"),
        },
      ],
      meta: {
        title: "Rush",
        icon: "slotplay.ico",
      },
    },
  ],
  Infinity: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/slotplay/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/slotplay/Home"),
        },
      ],
      meta: {
        title: "INFINITY",
        icon: "infinity.ico",
      },
    },
  ],
  Sd_new: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/sd_new/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/sd_new/Home"),
        },
      ],
      meta: {
        title: "SDNew",
        icon: "sd_new.ico",
      },
    },
  ],
  Sd_old: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/sd_old/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/sd_old/Home"),
        },
      ],
      meta: {
        title: "slot mania",
        icon: "sdold.ico",
      },
    },
  ],
};

export { paths };
